import { Disclosure, Menu, Transition } from "@headlessui/react";
import { useEffect, useState } from "react";
import {
  MenuIcon,
  QuestionMarkCircleIcon,
  CurrencyDollarIcon,
  XIcon,
  DownloadIcon,
  TicketIcon,
  QrcodeIcon,
  ShoppingBagIcon,
  VideoCameraIcon,
  CalendarIcon,
  ThumbUpIcon,
  CreditCardIcon,
  UserCircleIcon,
  CollectionIcon,
  SparklesIcon,
  UsersIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  DotsVerticalIcon,
} from "@heroicons/react/outline";
import { LoginIcon } from "@heroicons/react/solid";
import { LANGUAGES } from "hooks/useLanguage";
import Button from "components/Button/Button";
import CookieBanner from "components/CookieBanner/CookieBanner";
import NextLink from "next/link";
import { Link as ScrollLink } from "react-scroll";
import SupportModal from "components/SupportModal/SupportModal";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import Cookies from "js-cookie";
import { getUserLanguage } from "utils";

interface ClientNavProps {
  hasButtons?: boolean;
  imagesLoaded?: boolean;
  setProductMenu?;
  headerColor?: boolean;
  cookieBanner?: boolean;
  Language?: any;
  acceptLanguageHeader?;
  timeZone?: string;
}
export default function ClientNav({
  hasButtons = true,
  imagesLoaded,
  setProductMenu,
  headerColor,
  cookieBanner = true,
  Language,
  acceptLanguageHeader,
  timeZone,
}: ClientNavProps) {
  const [isMainOpen, setIsMainOpen] = useState(true);
  const [isProductOpen, setIsProductOpen] = useState(false);
  const [isMenuOpen, setMenuOpen] = useState(true);
  const [isMobile, setIsMobile] = useState(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const router = useRouter();
  const r = router.query.r;
  const [isSupportModalOpen, setisSupportModalOpen] = useState(false);

  const changeLanguageNext = (newLanguage) => {
    if (i18n.language !== newLanguage) {
      i18n.changeLanguage(newLanguage);
      Cookies.set("language", i18n.language);
    }
  };

  useEffect(() => {
    if (Cookies.get("language")) {
      const newLanguage = Language === "uk" ? "ua" : Cookies.get("language");
      changeLanguageNext(newLanguage);
    }
  }, [Cookies.get("language")]);

  useEffect(() => {
    if (window !== undefined) {
      setIsMobile(window.innerWidth <= 1024);
    }
  }, []);

  useEffect(() => {
    if (!Cookies.get("language")) {
      changeLanguageNext(getUserLanguage(timeZone, acceptLanguageHeader));
    }
  }, []);

  const handleProductClick = () => {
    setIsMainOpen(!isMainOpen);
    setIsProductOpen(!isProductOpen);
    isMobile && setProductMenu(!isProductOpen);
  };

  const handleSupportClick = () => {
    setMenuOpen(false);
    setisSupportModalOpen(true);
  };

  const pathSignUp = `/sign-up${r ? `?r=${r}` : ""}`;

  const savePath = () => {
    Cookies.set("path", pathSignUp);
  };

  return (
    <>
      {!cookieBanner ? null : <CookieBanner />}
      <div className="flex flex-col">
        <Disclosure
          as="nav"
          className={
            hasButtons
              ? "bg-slate-900 w-full relative z-30"
              : headerColor
              ? "bg-white w-full relative z-30"
              : "bg-black w-full relative z-30"
          }
        >
          {() => (
            <>
              <div
                className={`flex items-center justify-between px-5 
          md:justify-start lg:m-auto h-14 sm:h-16 ${
            headerColor && "sm:border-b"
          }`}
              >
                {headerColor ? (
                  <div className="relative order-1 flex items-center justify-between">
                    <div className="flex items-center lg:px-0">
                      <div className="flex-shrink-0 z-10">
                        <NextLink
                          href="/"
                          className="flex items-center flex-shrink-0"
                        >
                          <img
                            width={101}
                            height={24}
                            src="/img/logo/logo-client.svg"
                            alt="Waylight logo"
                            className="h-7"
                          />
                        </NextLink>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="relative order-1 flex items-center justify-between md:hidden">
                      <div className="flex items-center lg:px-0">
                        <div className="flex-shrink-0 z-10">
                          <NextLink
                            href="/"
                            className="flex items-center flex-shrink-0"
                          >
                            <img
                              width={101}
                              height={24}
                              src="/img/logo/waylight-standart-logo.svg"
                              alt="Waylight logo"
                              className="h-7"
                            />
                          </NextLink>
                        </div>
                      </div>
                    </div>
                    <div className="hidden relative order-1 md:flex md:items-center md:justify-between">
                      <div className="flex items-center lg:px-0">
                        <div className="flex-shrink-0 z-10">
                          <NextLink
                            href="/"
                            className="flex items-center flex-shrink-0"
                          >
                            <img
                              width={100}
                              height={24}
                              src="/img/logo/waylight-standart-logo.svg"
                              alt="Waylight logo"
                              className="h-7"
                            />
                          </NextLink>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                <div
                  className={`relative ${
                    headerColor ? "order-1" : "hidden"
                  } items-center flex-1 md:flex justify-end md:order-1`}
                >
                  {hasButtons ? (
                    <div className="relative z-50 hidden md:flex gap-2 flex-shrink-0 ml-3">
                      <div>
                        <NextLink
                          href=""
                          onMouseEnter={handleProductClick}
                          className="focus:outline-none"
                        >
                          <Button
                            iconAfter={true}
                            icon={
                              !isProductOpen ? (
                                <ChevronDownIcon className="w-4 h-4" />
                              ) : (
                                <ChevronUpIcon className="w-4 h-4" />
                              )
                            }
                            type="landing"
                          >
                            {t("productMenuItem")}
                          </Button>
                        </NextLink>
                      </div>
                      <div>
                        <ScrollLink
                          to="pricing"
                          smooth={true}
                          duration={500}
                          offset={!imagesLoaded && 750}
                          className="focus:outline-none font-bold"
                        >
                          <Button type="landing">{t("PricingMenuItem")}</Button>
                        </ScrollLink>
                      </div>
                      <div>
                        <NextLink href="" passHref>
                          <SupportModal type="landing" />
                        </NextLink>
                      </div>

                      <div className="focus:outline-none border border-slate-500 px-1 rounded-lg mx-4">
                        <NextLink href={pathSignUp}>
                          <Button onClick={savePath} type="landing">
                            {t("logIn")}
                          </Button>
                        </NextLink>
                      </div>
                    </div>
                  ) : null}
                </div>

                {hasButtons ? (
                  <>
                    <Menu
                      as="div"
                      className={
                        !isMenuOpen
                          ? "opacity-0"
                          : "opacity-100 flex-shrink-0 relative md:hidden lg:hidden"
                      }
                    >
                      {({ open }) => {
                        if (!open) {
                          setMenuOpen(true);
                        }
                        return (
                          <div>
                            <Menu.Button className="flex py-1 text-sm md:border-r md:border-gray-900 md:px-6 ">
                              <MenuIcon className="w-6 h-6 text-white" />
                            </Menu.Button>
                            <Transition
                              show={open}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items
                                static
                                className="origin-top-right absolute left-0 mt-3 w-56 rounded-md rounded-tl-none shadow-lg py-1
bg-white overflow-hidden ring-1 ring-black ring-opacity-5 focus:outline-none md:left-auto
md:right-4 md:rounded-tl-md md:rounded-tr-none"
                              >
                                <Menu.Item>
                                  <NextLink
                                    href={pathSignUp}
                                    onClick={savePath}
                                    className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left
    text-gray-700 hover:bg-gray-100 cursor-pointer"
                                  >
                                    <LoginIcon className="w-5 h-5 text-gray-500" />
                                    {t("logIn")}
                                  </NextLink>
                                </Menu.Item>
                                <Menu.Item>
                                  <div>
                                    {" "}
                                    <ScrollLink
                                      to="pricing"
                                      offset={!imagesLoaded && 600}
                                      smooth={true}
                                      duration={500}
                                      target="_blank"
                                      className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                    >
                                      <CurrencyDollarIcon className="w-5 h-5 text-gray-500" />
                                      {t("PricingMenuItem")}
                                    </ScrollLink>
                                  </div>
                                </Menu.Item>

                                <Menu.Item>
                                  <NextLink href="" passHref>
                                    <div
                                      className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                      onClick={handleProductClick}
                                    >
                                      <SparklesIcon className="w-5 h-5 text-gray-500" />
                                      {t("productMenuItem")}
                                    </div>
                                  </NextLink>
                                </Menu.Item>
                                <Menu.Item>
                                  <NextLink href="" passHref>
                                    <div
                                      onClick={handleSupportClick}
                                      className="ml-1 flex items-center gap-2 py-2.5 px-3 rounded-md text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                                    >
                                      <QuestionMarkCircleIcon className="w-6 h-6 text-gray-500" />
                                      <span>{t("support")}</span>
                                    </div>
                                  </NextLink>
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </div>
                        );
                      }}
                    </Menu>
                  </>
                ) : (
                  <Menu
                    as="div"
                    className={`flex-shrink-0 relative ${
                      headerColor ? "md:order-2 order-2" : "md:order-1"
                    }`}
                  >
                    {({ open }) => (
                      <div>
                        <Menu.Button
                          className={`flex py-1 text-sm ${
                            headerColor
                              ? "pl-6"
                              : "md:border-r md:border-gray-900"
                          } md:pl-6`}
                        >
                          {headerColor ? (
                            <DotsVerticalIcon className="w-6 h-6 text-black" />
                          ) : (
                            <MenuIcon className="w-6 h-6 text-white" />
                          )}
                        </Menu.Button>
                        <Transition
                          show={open}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            static
                            className={`origin-top-right absolute ${
                              headerColor ? "right-0" : "left-0"
                            } mt-3 w-56 rounded-md rounded-tl-none shadow-lg py-1
                                    bg-white overflow-hidden ring-1 ring-black ring-opacity-5 focus:outline-none md:left-auto
                                    md:right-4 md:rounded-tl-md md:rounded-tr-none`}
                          >
                            <Menu.Item>
                              <NextLink
                                href="https://t.me/waylightsupportbot"
                                passHref
                                target="_blank"
                                className="flex items-center gap-2 w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                              >
                                <QuestionMarkCircleIcon className="w-5 h-5 text-gray-500" />
                                {t("support")}
                              </NextLink>
                            </Menu.Item>
                          </Menu.Items>
                        </Transition>
                      </div>
                    )}
                  </Menu>
                )}
                <Menu as="div" className="order-1 relative md:ml-4">
                  {({ open }) => (
                    <div>
                      <Menu.Button
                        className={`flex items-center gap-2 py-1 ${
                          headerColor ? "text-black" : "text-white"
                        } focus:outline-none focus:ring-2 focus:ring-white`}
                      >
                        {i18n.language.toUpperCase()}
                        <ChevronDownIcon
                          className={`w-4 h-4 ${
                            headerColor ? "text-black" : ""
                          }`}
                        />
                      </Menu.Button>
                      <Transition
                        show={open}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="origin-top-right absolute right-0 mt-3 w-48 rounded-md rounded-tr-none shadow-lg py-1
                      bg-white overflow-hidden ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                          {LANGUAGES.map((lang) => (
                            <Menu.Item key={lang.id}>
                              <button
                                className="block w-full px-4 py-2 text-sm text-left text-gray-700 hover:bg-gray-100 cursor-pointer"
                                onClick={() => {
                                  changeLanguageNext(lang.code.toLowerCase());
                                }}
                              >
                                {lang.name}
                              </button>
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </div>
                  )}
                </Menu>
              </div>
            </>
          )}
        </Disclosure>
      </div>
      {isSupportModalOpen && (
        <SupportModal
          setisSupportModalOpen={setisSupportModalOpen}
          type="landingMobile"
        />
      )}
      {isProductOpen && (
        <div
          onMouseLeave={handleProductClick}
          className={`fixed overflow-hidden top-0 bottom-0 overflow-y-auto xl:bottom-auto xl:top-auto xl:absolute z-50  xl:w-11/12 xl:max-w-screen-2xl xl:mx-auto left-0 right-0 xl:overflow-hidden xl:rounded-b-xl`}
        >
          <button
            className="absolute cursor-pointer top-0 right-0 m-4  xl:hidden"
            onClick={handleProductClick}
          >
            <XIcon className="w-6 h-6 text-black" />
          </button>
          <div className="bg-white w-full h-full overflow-auto">
            <div className="px-5 pt-7 pb-0">
              <h1 className="text-4xl xl:hidden font-extrabold mt-8 mb-8">
                {t("product")}
              </h1>
              <ul className="text-slate-900 xl:grid xl:grid-cols-4">
                <div className="xl:w-[400px]">
                  <li className="flex mb-6 md:mb-4 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <img
                        alt="telegram"
                        src="/img/telegram-product.svg"
                        className="w-3 h-3"
                      />
                    </div>
                    {t("paidSubscriptionCommunitiesTelegram")}
                  </li>

                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      {" "}
                      <img
                        alt="telegram"
                        src="/img/telegram-product.svg"
                        className="w-3 h-3"
                      />
                    </div>
                    {t("paidGroupChannelAccessTelegram")}
                  </li>

                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <DownloadIcon className="w-3 h-3" />
                    </div>
                    {t("paidAccessDigitalProducts")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <DownloadIcon className="w-3 h-3" />
                    </div>
                    {t("paidAccessCourses")}
                  </li>
                </div>
                <div className="xl:w-[250px] xl:ml-28">
                  <li className="flex mb-6 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-5 flex items-center justify-center rounded-t-full rounded-l-full">
                      <TicketIcon className="w-3 h-3" />
                    </div>
                    {t("ticketsPersonEvents")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <QrcodeIcon className="w-3 h-3" />
                    </div>
                    {t("ticketsScanner")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <TicketIcon className="w-3 h-3" />
                    </div>
                    {t("ticketsOnlineEvents")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <ShoppingBagIcon className="w-3 h-3" />
                    </div>
                    {t("paidServices")}
                  </li>
                </div>
                <div className="xl:w-[250px] xl:ml-16">
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <VideoCameraIcon className="w-3 h-3" />
                    </div>
                    {t("paidVideoSessions")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <CalendarIcon className="w-3 h-3" />
                    </div>
                    {t("paidAppointments")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <ThumbUpIcon className="w-3 h-3" />
                    </div>
                    {t("tipping")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <CreditCardIcon className="w-3 h-3" />
                    </div>
                    {t("securePayments")}
                  </li>
                </div>
                <div className="xl:w-[280px] xl:ml-8">
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <UserCircleIcon className="w-3 h-3" />
                    </div>
                    {t("businessWebsite")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <CollectionIcon className="w-3 h-3" />
                    </div>
                    {t("portfolioProduct")}
                  </li>
                  <li className="flex mb-6 lg:mb-8 text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <SparklesIcon className="w-3 h-3" />
                    </div>
                    {t("reviewsProduct")}
                  </li>
                  <li className="flex text-sm font-medium">
                    <div className="bg-black shrink-0 basis-auto mr-3 text-white h-5 w-[18.29px] flex items-center justify-center rounded-t-full rounded-l-full">
                      <UsersIcon className="w-3 h-3" />
                    </div>
                    {t("customersDataManagement")}
                  </li>
                </div>
              </ul>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
