import { useEffect, useState } from "react";

export type BrowserType = "chrome" | "firefox" | "safari" | "edge" | "another";

function useBrowser() {
  const [isMobile, setMobile] = useState(false);
  const [browser, setBrowser] = useState<BrowserType>();

  useEffect(() => {
    const userAgent =
      typeof navigator === "undefined" ? "" : navigator.userAgent;
    const mobile = Boolean(
      userAgent.match(
        /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
      )
    );
    setMobile(mobile);

    if (userAgent.includes("Chrome") && userAgent.includes("Edg")) {
      return setBrowser("edge");
    } else if (userAgent.includes("Chrome") || userAgent.includes("CriOS")) {
      setBrowser("chrome");
    } else if (navigator.userAgent.includes("Safari")) {
      setBrowser("safari");
    } else if (navigator.userAgent.includes("Firefox")) {
      setBrowser("firefox");
    } else {
      setBrowser("another");
    }
  }, []);

  return {
    isMobile,
    browser,
  };
}

export default useBrowser;
