import {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  useCallback,
} from "react";

type SetValue<T> = Dispatch<SetStateAction<T>>;

function useLocalStorage<T>(key: string, initialValue: T): [T, SetValue<T>] {
  const [storedValue, setStoredValue] = useState<T>(() => {
    const isBrowser = typeof window !== "undefined";

    if (!isBrowser) {
      return initialValue;
    }

    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.warn(`Error reading localStorage key “${key}”:`, error);
      return initialValue;
    }
  });

  const setValue = useCallback<SetValue<T>>(
    (value) => {
      if (typeof window === "undefined") {
        console.warn(
          `Cannot set localStorage key “${key}” because environment is not a client.`
        );
        return;
      }

      try {
        const newValue = value instanceof Function ? value(storedValue) : value;
        window.localStorage.setItem(key, JSON.stringify(newValue));
        setStoredValue(newValue);
      } catch (error) {
        console.warn(`Error setting localStorage key “${key}”:`, error);
      }
    },
    [key, storedValue]
  );

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    const handleStorageChange = () => {
      try {
        const item = window.localStorage.getItem(key);
        const currentValue = item ? JSON.parse(item) : initialValue;
        if (currentValue !== storedValue) {
          setStoredValue(currentValue);
        }
      } catch (error) {
        console.warn(`Error reading localStorage key “${key}”:`, error);
      }
    };

    window.addEventListener("storage", handleStorageChange);
    return () => window.removeEventListener("storage", handleStorageChange);
  }, [key, storedValue, initialValue]);

  return [storedValue, setValue];
}

export default useLocalStorage;
