import { Fragment, useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { MailIcon, XIcon } from "@heroicons/react/outline";
import cx from "classnames";
import { QuestionMarkCircleIcon } from "@heroicons/react/outline";
import useBrowser from "hooks/useBrowser";
import Button from "components/Button/Button";
import { useTranslation } from "react-i18next";

export default function SupportModal({
  type = "default",
  isIcon = false,
  isClient,
  setisSupportModalOpen,
}: {
  type?: "default" | "text" | "icon" | "landing" | "landingMobile";
  isIcon?: boolean;
  isClient?: boolean;
  setisSupportModalOpen?;
}) {
  const [isOpenSupport, setIsOpenSupport] = useState(false);
  const { isMobile } = useBrowser();
  const { t } = useTranslation();
  const isDefault = type === "default";
  const isLanding = type === "landing";
  const isLandingMobile = type === "landingMobile";

  function openModal() {
    setIsOpenSupport(true);
  }

  function closeModal() {
    setIsOpenSupport(false);
    isLandingMobile && setisSupportModalOpen(false);
  }

  useEffect(() => {
    if (isLandingMobile) {
      openModal();
    }
  }, []);

  return (
    <>
      {isDefault ? (
        !isMobile ? (
          <div
            onClick={openModal}
            className={cx(
              "flex items-center gap-2 py-2.5 px-3 rounded-md text-sm text-slate-900 cursor-pointer",
              { "hover:bg-gray-100": !isIcon }
            )}
          >
            <QuestionMarkCircleIcon
              className={cx(
                { "w-6 h-6 text-slate-50": isIcon },
                { "w-5 h-5 text-slate-500": !isIcon },
                { "w-6 h-6 text-slate-700": isClient }
              )}
            />
            <span className={isIcon ? "hidden" : null}>{t("support")}</span>
          </div>
        ) : (
          <button
            onClick={openModal}
            className="py-2 px-3 rounded-md flex items-center font-medium w-full"
          >
            <QuestionMarkCircleIcon
              className={cx(
                { "w-7 h-7 text-slate-50": isIcon },
                { "w-5 h-5 text-slate-500 mr-4": !isIcon },
                { "w-6 h-6 text-slate-700": isClient }
              )}
            />
            <span className={isIcon ? "hidden" : null}>{t("support")}</span>
          </button>
        )
      ) : isLandingMobile ? (
        <div onClick={openModal} className={cx("hidden")}>
          <QuestionMarkCircleIcon
            className={cx(
              { "w-6 h-6 text-slate-50": isIcon },
              { "w-5 h-5 text-slate-500": !isIcon },
              { "w-6 h-6 text-slate-700": isClient }
            )}
          />
          <span className={isIcon ? "hidden" : null}>{t("support")}</span>
        </div>
      ) : isLanding ? (
        <div onClick={openModal}>
          <Button type="landing">{t("support")}</Button>
        </div>
      ) : (
        <span
          onClick={openModal}
          className="text-custom-dark-blue cursor-pointer"
        >
          {t("supportAlternative")}
        </span>
      )}

      <Transition appear show={isOpenSupport || false} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-75" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-10 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="transform overflow-hidden rounded-lg bg-slate-900 p-10 py-14 text-left align-middle shadow-xl transition-all sm:p-14 sm:pb-20">
                  <XIcon
                    className="absolute top-4 right-4 w-6 rounded-md stroke-white cursor-pointer active:outline-none active:ring-2 active:ring-offset-2 active:ring-offset-black active:ring-gray-400"
                    onClick={closeModal}
                  />
                  <Dialog.Title
                    as="h3"
                    className="text-sm leading-5 font-semibold tracking-wider uppercase text-slate-400"
                  >
                    {t("requestSupport")}
                  </Dialog.Title>
                  <ul className="grid grid-cols-1 gap-4 mt-7 text-base leading-6 font-normal text-slate-50 sm:grid-cols-2">
                    <li className="w-64">
                      <img
                        className="inline-block w-5 h-5 mr-3"
                        src="/img/telegram-support.svg"
                        alt="Telegram"
                      />
                      <a
                        className="hover:underline"
                        href="https://t.me/waylightsupportbot"
                        target="_blank"
                        rel="noreferrer"
                      >
                        @waylightsupportbot
                      </a>
                    </li>
                    <li className="w-64">
                      <img
                        className="inline-block w-5 h-5 mr-3"
                        src="/img/whatsapp.svg"
                        alt="Whatsapp"
                      />
                      <a
                        className="hover:underline"
                        href="https://api.whatsapp.com/send/?phone=13024672761"
                        target="_blank"
                        rel="noreferrer"
                      >
                        @waylight
                      </a>
                    </li>
                    <li className="w-64">
                      <MailIcon className="inline-block w-5 h-5 mr-3" />
                      <a
                        className="hover:underline"
                        href="mailto:support@waylight.me"
                        target="_blank"
                        rel="noreferrer"
                      >
                        support@waylight.me
                      </a>
                    </li>
                    <li className="w-64">
                      <img
                        className="inline-block w-5 h-5 mr-3"
                        src="/img/social-links/facebook.svg"
                        alt="Facebook"
                      />
                      <a
                        className="hover:underline"
                        href="https://m.me/waylightme"
                      >
                        waylightme
                      </a>
                    </li>
                  </ul>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
