import { useMutation } from "@tanstack/react-query";
import { api } from "utils/api";
import { UKRAINIAN_TIMEZONES } from "utils";
import { SPANISH_TIMEZONES } from "utils";
import { GERMAN_TIMEZONES } from "utils";
import { CZECH_TIMEZONES } from "utils";
import { PORTUGUESE_TIMEZONES } from "utils";
import { HINDI_TIMEZONES } from "utils";
import { FRENCH_TIMEZONES } from "utils";
import { ITALIAN_TIMEZONES } from "utils";
import { POLISH_TIMEZONES } from "utils";

interface LANGUAGE {
  id: number;
  name: string;
  code:
    | "EN"
    | "UA"
    | "RU"
    | "ES"
    | "CS"
    | "DE"
    | "PT"
    | "FR"
    | "IT"
    | "HI"
    | "PL";
}

export const LANGUAGES: Array<LANGUAGE> = [
  { id: 1, name: "English", code: "EN" },
  { id: 2, name: "Español", code: "ES" },
  { id: 3, name: "Українська", code: "UA" },
  { id: 4, name: "Русский", code: "RU" },
  { id: 5, name: "Čeština", code: "CS" },
  { id: 6, name: "Deutsch", code: "DE" },
  { id: 7, name: "Português", code: "PT" },
  { id: 8, name: "हिन्दी", code: "HI" },
  { id: 9, name: "Français", code: "FR" },
  { id: 10, name: "Italiano", code: "IT" },
  { id: 11, name: "Polski", code: "PL" },
];

export const getLanguageByCode = (code) => {
  return LANGUAGES.find((language) => language.code === code);
};

export const getInitialLanguage = (languages) => {
  const hasWindow = typeof window !== "undefined";
  const lang = hasWindow && localStorage.getItem("lang");
  const acceptLanguageHeader =
    hasWindow && sessionStorage.getItem("acceptLanguageHeader");
  const langSession = hasWindow && sessionStorage.getItem("lang");
  let userLanguage = null;
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const UKRAINE = UKRAINIAN_TIMEZONES.includes(timeZone);
  const SPANISH = SPANISH_TIMEZONES.includes(timeZone);
  const CZECH = CZECH_TIMEZONES.includes(timeZone);
  const GERMAN = GERMAN_TIMEZONES.includes(timeZone);
  const PORTUGUESE = PORTUGUESE_TIMEZONES.includes(timeZone);
  const ITALIAN = ITALIAN_TIMEZONES.includes(timeZone);
  const HINDI = HINDI_TIMEZONES.includes(timeZone);
  const FRENCH = FRENCH_TIMEZONES.includes(timeZone);
  const POLISH = POLISH_TIMEZONES.includes(timeZone);

  if (hasWindow) {
    if (acceptLanguageHeader) {
      const browserLanguage = acceptLanguageHeader.split(",")[0];
      if (lang || langSession) {
        userLanguage = JSON.parse(lang);
      } else {
        switch (browserLanguage) {
          case "uk":
            userLanguage = { id: 3, name: "Українська", code: "UA" };
            break;
          case "es":
            userLanguage = { id: 2, name: "Español", code: "ES" };
            break;
          case "cs":
            userLanguage = { id: 5, name: "Čeština", code: "CS" };
            break;
          case "de":
            userLanguage = { id: 6, name: "Deutsch", code: "DE" };
            break;
          case "pt":
            userLanguage = { id: 7, name: "Português", code: "PT" };
            break;
          case "it":
            userLanguage = { id: 10, name: "Italiano", code: "IT" };
            break;
          case "hi":
            userLanguage = { id: 8, name: "हिन्दी", code: "HI" };
            break;
          case "fr":
            userLanguage = { id: 9, name: "Français", code: "FR" };
            break;
          case "pl":
            userLanguage = { id: 11, name: "Polski", code: "PL" };
            break;
          case "en":
            userLanguage = { id: 11, name: "English", code: "EN" };
            break;
          default:
            userLanguage = false;
        }
      }
    }

    if (!userLanguage) {
      if (lang || langSession) {
        userLanguage = JSON.parse(lang);
      } else {
        switch (true) {
          case UKRAINE:
            userLanguage = { id: 3, name: "Українська", code: "UA" };
            break;
          case SPANISH:
            userLanguage = { id: 2, name: "Español", code: "ES" };
            break;
          case CZECH:
            userLanguage = { id: 5, name: "Čeština", code: "CS" };
            break;
          case GERMAN:
            userLanguage = { id: 6, name: "Deutsch", code: "DE" };
            break;
          case PORTUGUESE:
            userLanguage = { id: 7, name: "Português", code: "PT" };
            break;
          case ITALIAN:
            userLanguage = { id: 10, name: "Italiano", code: "IT" };
            break;
          case HINDI:
            userLanguage = { id: 8, name: "हिन्दी", code: "HI" };
            break;
          case FRENCH:
            userLanguage = { id: 9, name: "Français", code: "FR" };
            break;
          case POLISH:
            userLanguage = { id: 11, name: "Polski", code: "PL" };
            break;
          default:
            userLanguage = { id: 11, name: "English", code: "EN" };
        }
      }
    }
  }

  return userLanguage ? userLanguage : languages[0];
};

export const useLanguage = () => {
  const currentLanguage = getInitialLanguage(LANGUAGES);

  const mutation = useMutation((payload: { language: string }) =>
    api.patch("/users/v1/users", payload)
  );

  const changeLanguage = (lang: LANGUAGE, updateUser: boolean = true) => {
    if (updateUser) {
      let langLower = lang.code.toLowerCase();

      mutation.mutate({ language: langLower });
    }

    localStorage.setItem("lang", JSON.stringify(lang));
    sessionStorage.setItem("lang", JSON.stringify(lang));
    // window.location.reload();
  };

  return { currentLanguage, changeLanguage };
};
