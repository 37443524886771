import useLocalStorage from "hooks/useLocalStorage";
import { translate } from "utils";
import { useState, useEffect } from "react";

const CookieBanner = () => {
  const [cookiePermission, setCookiePermission] = useLocalStorage(
    "cookiePermission",
    false
  );
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  if (cookiePermission) {
    return null;
  }

  const handleClick = () => {
    setCookiePermission(true);
  };

  return (
    <div className="bg-yellow-400 fixed left-0 right-0 bottom-6 mx-auto sm:left-auto sm:right-6 z-50 w-11/12 md:w-4/12 shadow-2xl rounded-lg">
      <div className="mx-auto py-4 px-4 md:px-6">
        <div className="flex flex-col md:flex-row gap-2.5 lg:gap-4 items-center justify-between">
          <p className="grow text-justify text-xs font-medium break-words">
            <span>{translate("cookiePermission")}</span>{" "}
            <a
              href="/docs/Waylight-PrivacyPolicy.pdf"
              target="_blank"
              rel="noopener noreferrer"
              className="underline underline-offset-4"
            >
              {translate("readMore")}
            </a>
            .
          </p>
          <button
            data-testid="cookieBanner"
            className="w-fit shrink-0 text-xs px-4 font-medium text-white py-2 md:py-2.5 bg-amber-500 rounded-lg shadow-sm"
            onClick={handleClick}
          >
            {translate("accept")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CookieBanner;
