export const api = {
  get: (path) => fetchApi(path),
  post: (path, data) => fetchApi(path, data, "POST"),
  postBlob: (path, data) => fetchApi(path, data, "POST", "blob"),
  patch: (path, data = null) => fetchApi(path, data, "PATCH"),
  delete: (path, data = null) => fetchApi(path, data, "DELETE"),
  getText: (path) => fetchApi(path, null, "GET", "text"),
  getBlob: (path) => fetchApi(path, null, "GET", "blob"),
};

async function fetchApi(
  path: string,
  data?: any,
  method: "GET" | "POST" | "PATCH" | "DELETE" = "GET",
  type: "default" | "blob" | "text" = "default"
) {
  const isDefaultType = type === "default";
  const isTextType = type === "text";
  const isBlobType = type === "blob";

  const headers = isTextType
    ? { "Content-Type": "text/plain" }
    : isBlobType
    ? {}
    : { "Content-Type": "application/json" };

  const response = await fetch("/api" + path, {
    method,
    headers,
    ...(data ? { body: isDefaultType ? JSON.stringify(data) : data } : {}),
  });

  if (!response.ok) {
    const error = await response.json();
    if (error?.detail) {
      throw new Error(error.detail);
    } else {
      throw error;
    }
  }

  if (response.status === 204) {
    return;
  }

  if (isBlobType) {
    return await response.blob();
  }

  return isTextType ? await response.text() : await response.json();
}
